<template>
    <div class="filter">
        <label class="filter-label">{{ label }}</label>
        <div class="filter-field" @click="openDropdown()" :class="[`${className}-filter`, {'dropdown-open': dropdownExpanded}]">

            <div class="filter-display">
                <input 
                    class="options-display"
                    :class="`${className}-display`" 
                    :value="optionsDisplay.text" 
                    :title="optionsDisplay.title" 
                    readonly/>
            </div>
            
            <transition name="fade">
                <ul class="filter-dropdown" :class="{'show': dropdownExpanded}" v-show="dropdownExpanded">

                    <li v-for="option in optionsDropdown" :key="option[field_id]"
                        class="dropdown-option"
                        :class="[
                            `${className}-dropdown`,
                            {'selected': checkSelected(option[field_id])}
                        ]"
                        :title="option[field_name]"
                        @click="toggleOption(option)">
                            {{ option[field_name] }}
                    </li>
                    
                </ul>
            </transition>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        },
        field: {
            type: String,
            required: true
        },
        optionsDropdown: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        field_id: {
            type: String,
            default: "id"
        },
        field_name: {
            type: String,
            default: "name"
        }
    },

    data() {
        return {
            selectedOptions: this.value,
            dropdownExpanded: false,
        }
    },

    computed: {
        // ======================== Subtitle treatment here ========================
        selectStrings() {
            let selectStrings = {}

            if (this.field == "Unidade Operacional") {
                selectStrings.label = "Unidade Operacional"
                selectStrings.allOptions = "Todas as Unidades"
                selectStrings.selectedOptions = "Unidades Selecionadas"
                selectStrings.class = "uo"
                
            } else if (this.field == "Empresa") {
                selectStrings.label = "Selecionar Empresa"
                selectStrings.allOptions = "Todas as Empresas"
                selectStrings.selectedOptions = "Empresas Selecionadas"
                selectStrings.class = "company"
            }

            return selectStrings
        },
        // =========================================================================
        
        label() {
            return this.selectStrings.label
        },
        className() {
            return this.selectStrings.class
        },
        displayTextAll() {
            return this.selectStrings.allOptions
        },
        displayTextSelected() {
            return this.selectStrings.selectedOptions
        },

        optionsDisplay() {
            let optionsDisplay = {}

            if (this.selectedOptions.length == 0) {
                optionsDisplay.text = this.displayTextAll
                optionsDisplay.title = ""

                for (const option of this.optionsDropdown) {
                    optionsDisplay.title += `${option[this.field_name]} \n`
                }

            } else if (this.selectedOptions.length == 1) {
                optionsDisplay.text = this.selectedOptions[0][this.field_name]
                optionsDisplay.title = this.selectedOptions[0][this.field_name]

            } else {
                optionsDisplay.text = `${this.selectedOptions.length} ${this.displayTextSelected}`
                optionsDisplay.title = ""

                for (const option of this.selectedOptions) {
                    optionsDisplay.title += `${option[this.field_name]} \n`
                }
            }

            return optionsDisplay
        }
    },

    watch: { 
        value() {
            this.selectedOptions = this.value
        }
    },

    mounted() {
        document.addEventListener("click", this.closeDropdowns)
    },
    beforeDestroy() {
        document.removeEventListener("click", this.closeDropdowns)
    },

    methods: {
        openDropdown() {
            if (!this.disabled) this.dropdownExpanded = true
        },

        toggleOption(clickedOption) {
            const index = this.selectedOptions.findIndex(option => option[this.field_id] == clickedOption[this.field_id])

            if (index == -1) {
                this.selectedOptions.push(clickedOption)
            } else {
                this.selectedOptions.splice(index, 1)
            }

            this.$emit("input", this.selectedOptions)
        },

        checkSelected(optionId) {
            for (const option of this.selectedOptions) {
                if (option[this.field_id] == optionId) return true
            }
            return false
        },
        
        closeDropdowns(event) {
            const classList = [`${this.className}-filter`, `${this.className}-dropdown`, "filter-display", `${this.className}-display`]
            let validTarget = false

            for (let c of classList) {
                if (event.target.classList.contains(c)) validTarget = true
            }

            if (!validTarget) this.dropdownExpanded = false
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";
@import "@/views/custom-pages/dashboard/shared-styles/filter-field.scss";

.filter-display {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    padding: 6px;

    .options-display {
        width: 100%;
        height: 100%;
        background-color: $custom-blue;
        color: #fff;
        border-radius: 4px;
        border: none;
        outline: none;
        cursor: pointer;
        padding-left: responsive-width(10px);
        padding-right: responsive-width(10px);
        display: flex;
        align-items: center;
        text-align: center;
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>