<template>
    <div class="dashboard-filter panel">
                <b-row v-if="nacional">
                    <b-col lg="4" class="mb-lg-0 mb-1">
                        <SingleSelectFilter 
                        :field = "'Grupo'"
                        :optionsDropdown = "groupDropdown"
                        :deselectable = true
                        field_id="id_grupo"
                        field_name="descricao_grupo"
                        v-model = "selectedGroup"
                        @input="paramsUpdate"
                        />
                    </b-col>
                    <b-col lg="4" class="mb-lg-0 mb-1">
                        <SingleSelectFilter 
                    :field = "'UF'"
                    :optionsDropdown = "statesDropdown"
                    :deselectable = true
                    field_id="uf"
                    field_name="uf"
                    tooltip="Filtre para visualizar os dados de acordo com a distribuição estadual das suas empresas"
                    v-model = "selectedState"
                    @input="paramsUpdate"
                    />
                    
                    </b-col>
                    <b-col lg="4" class="mb-lg-0 mb-1">

                    <SingleSelectFilter 
                    :field = "'Empresa'"
                    field_id="id_empresa"
                    field_name="nome_empresa"
                    :optionsDropdown = "companiesDropdown" 
                    v-model = "selectedCompany"
                    @input="paramsUpdate"
                    />

                    </b-col>
                </b-row>
                <b-row class="pt-0 pt-lg-2">
                    <b-col lg="4" v-if="nacional" class="mb-lg-0 mb-1">
                        <SingleSelectFilter 
                    :field = "'Endereço'"
                    field_id="id_empresa_endereco"
                    field_name="endereco"
                    field_label="descricao"
                    :disabled="!selectedCompany"
                    :optionsDropdown = "adressesDropdown" 
                    tooltip="Filtre para visualizar os dados de acordo com o endereço atualizado do cadastro da sua empresa"
                    v-model = "selectedAdress"
                    />
                    </b-col>
                    <b-col lg="4" v-if="nacional" class="mb-lg-0 mb-1">
                    <SingleSelectFilter 
                        :field = "'DR SESI'"
                        :optionsDropdown = "departmentDropdown"
                        :deselectable = true
                        field_id="id_operador"
                        field_name="uf_operador"
                        :tooltip="'Filtre para visualizar os dados de acordo com o atendimento realizado pelo SESI'"
                        v-model = "selectedDepartment"
                        @input="paramsUpdate"
                        />
                    </b-col>
                    <b-col lg="3" v-if="!nacional" class="mb-lg-0 mb-1">
                        <SingleSelectFilter 
                        :field = "'Estado'"
                        :optionsDropdown = "filterFields.allStates"
                        :deselectable = true
                        field_id="uf"
                        field_name="nome_estado"
                        v-model = "selectedState"
                        @input="paramsUpdate"
                        />

                    </b-col>
                    <b-col lg="3" v-if="!nacional" class="mb-lg-0 mb-1">
                        <MultipleSelectFilter 
                        :field = "'Empresa'"
                        field_id="id_empresa"
                        field_name="nome_empresa"
                        :optionsDropdown = "companiesDropdown" 
                        v-model = "selectedCompanies"
                        />
                    </b-col>
                    <b-col :lg="nacional ? 4 : 3">
                    <SingleSelectFilter 
                    :field = "'Campanha'"
                    :optionsDropdown = "campaignsDropdown"
                    v-model = "selectedCampaign"
                    @input="resetFilters"
                    />
                    </b-col>
                    <b-col sm="12" :lg="nacional ? 12 : 3"
                    > 
                    <div class="d-flex align-items-center mt-1 pt-1"
                    :class="{
                    'justify-content-center': nacional
                    }">
                        <button
                            type="button"
                            class="btn custom-blue mr-1"
                            @click="filterData"
                        >
                            Filtrar
                        </button>
    
                        <button
                            type="button"
                            class="btn custom-outline-blue"
                            @click="resetFilters"
                        >
                            Limpar
                        </button>
                    </div>
                    </b-col>
                </b-row>
    </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton
} from 'bootstrap-vue';
import dashUtilsMixin from "../../shared-mixins/dashUtilsMixin"
import SingleSelectFilter from "@/views/custom-pages/dashboard/shared-components/SingleSelectFilter.vue"
import MultipleSelectFilter from "@/views/custom-pages/dashboard/shared-components/MultipleSelectFilter.vue"
import FilterTags from "@/views/custom-pages/dashboard/shared-components/FilterTags.vue"

export default {
    components: {
        SingleSelectFilter,
        MultipleSelectFilter,
        FilterTags,
        BCol,
        BRow,
        BButton
    },

    mixins: [ dashUtilsMixin ],

    props: {
        filterFields: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedState: null,
            selectedGroup: null,
            selectedCompanies: [],
            selectedAdress: null,
            selectedCompany: null,
            selectedDepartment: null,
            selectedCampaign: this.value,
            all: {
                states: [],
                groups: [],
                departments: [],
                companies: []
            }
        }
    },

    computed: {
        campaignChange() {
            return this.value.id != this.selectedCampaign.id
        },
        nacional() {
            return this.selectedCampaign.nacional
        },
        statesDropdown() {
            let statesSelect = this.filterFields.allStates

            this.orderAlphabetically(statesSelect, 'uf');
            return statesSelect
        },
        groupDropdown() {
            let groupSelect = this.filterFields.allGroups

            this.orderAlphabetically(groupSelect, 'descricao_grupo')
            return groupSelect
        },
        departmentDropdown() {
            let operatorSelect = this.filterFields.allOperators

            this.orderAlphabetically(operatorSelect, 'uf_operador')
            return operatorSelect
        },
        companiesDropdown() {
            let companiesList = this.filterFields.allCompanies

            if(this.selectedCompany){
                let companyExist = companiesList.find(company => company.id_empresa == this.selectedCompany.id_empresa)
                if(!companyExist) {
                    this.selectedCompany = null
                }else{
                    this.selectedCompany = companyExist
                }
            }

            this.orderAlphabetically(companiesList, 'nome_empresa')
            return companiesList
        },
        adressesDropdown() {
            let adressesList = []
            if(this.selectedCompany){
                adressesList = this.selectedCompany.enderecos
            }

            if(this.selectedAdress){
                let adressExist = adressesList.find(adress => adress.id_empresa_endereco == this.selectedAdress.id_empresa_endereco)
                if(!adressExist) {
                    this.selectedAdress = null
                }
            }
            
            this.orderAlphabetically(adressesList, 'id_empresa_endereco')

            return adressesList
        },
        campaignsDropdown() {
            let campaignsList = this.filterFields.allCampaigns

            this.orderAlphabetically(campaignsList)
            this.orderByRecentDate(campaignsList)
            return campaignsList
        },
        companiesToFilter() {
            let companiesToFilter = []

            if (this.selectedCompany){    
                companiesToFilter = [this.selectedCompany]
            }
            else if (this.selectedCompanies.length) {
                companiesToFilter = this.selectedCompanies
            }
            else {
                this.selectedAdress = null
                companiesToFilter = [...this.companiesDropdown]
            }

            
            companiesToFilter.sort((a, b) => a.id - b.id)
            return companiesToFilter
        },
        adressesToFilter() {
            let adressesToFilter = []

            if(this.selectedAdress) {
                adressesToFilter = [this.selectedAdress.id_empresa_endereco]
            }
            else if(this.selectedCompany) {
                adressesToFilter = this.selectedCompany.enderecos.map(adress => adress.id_empresa_endereco)
            }
            else {
                adressesToFilter = this.companiesDropdown.map(company => company.enderecos.map(adress => adress.id_empresa_endereco)).flat()
            }
            
            return adressesToFilter
        }
    },
    methods: { 
        filterData() {
            const fieldsToFilter = {
                companies: this.companiesToFilter.map(company => company.id_empresa),
                campaign: [ this.selectedCampaign.id ],
                adresses: this.nacional ? this.adressesToFilter : [],
                uf: this.selectedState && this.selectedCampaign.nacional ? this.selectedState.uf : null,
                id_operador: this.selectedDepartment ? this.selectedDepartment.id_operador : null,
                nacional: this.nacional
            }
            
            this.$emit("filterUpdade", fieldsToFilter)
        },
        paramsUpdate(){
            this.$emit('paramsUpdate', {
                id_campanha: this.selectedCampaign.id,
                id_grupo: this.selectedGroup ? this.selectedGroup.id_grupo : null,
                id_operador: this.selectedDepartment ? this.selectedDepartment.id_operador : null,
                id_empresa: this.selectedCompany ? this.selectedCompany.id_empresa : null,
                uf_empresa: this.selectedState ? this.selectedState.uf : null
            })
        },
        resetFilters() {
            this.selectedState = null
            this.selectedGroup = null
            this.selectedDepartment = null
            this.selectedCompanies = []
            this.selectedAdress = null
            this.selectedCompany = null
            
            this.$emit('paramsUpdate', {
                id_campanha: this.selectedCampaign.id
            })
        }
    }
}
</script>