import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'
import campaignState from '@/store/campaignState'


// Dashboard Admin Empresa - Filter Fields
export const getDashboardFilterOptions = (params) => new Promise((resolve, reject) => {
    params = {
        id_grupo: params.id_grupo,
        id_campanha: params.id_campanha,
        id_empresa: params.id_empresa
    }

    axiosIns
        .get(rotasAPI.grupoOperadorUf(), { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
})

// Dashboard Admin Empresa - Dashboard Data
export const getDashboardData = (props) => new Promise((resolve, reject) => {
    const headers = {
        'campanha': props.campaign,
        'empresas': props.companies,
        'enderecos': props.adresses
    }

    const params = {
        uf: props.uf,
        idDepartamento: props.id_operador,
        colunaOrdenar: props.sortBy,
        ordem: props.sortDesc ? 'desc' : 'asc',
    }

    axiosIns
        .get(rotasAPI.getDashboardDataAdminEmpresa(), { headers, params})
        .then(response => resolve(response))
        .catch(error => reject(error))
})

export const getCompaniesData = (params) => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.empresaCampanha(), { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
})